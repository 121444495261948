






































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { ApplicationStatus } from "@/models/businessApplication";
import DrawdownComponent from "@/components/Drawdown.vue";
import agent from "@/helpers/agent";
import Comments from "@/components/Comments.vue";
import FormT, { ApplicationDetails } from "@/models/formT";
import numberWithCommas from "@/helpers/numberWithCommas";

@Component({ components: { DrawdownComponent, Comments } })
export default class FormRView extends Vue {
  formT: FormT = {
    id: "",
    name: "",
    nameOfFinancier: "",
    addressOfFinancier: "",
    reference: "",
    branch: "",
    status: ApplicationStatus.Pending,
    applications: [],
  };
  pagination = {
    sortBy: "Name",
    descending: false,
    page: 0,
    rowsPerPage: 10,
    rowsNumber: 10,
  };
  loading = true;
  filter = "";
  columns = [
    {
      name: "Reference",
      required: true,
      label: "Reference",
      align: "left",
      field: (row: ApplicationDetails) => row.reference,
      sortable: true,
      classes: "bg-grey-2 ellipsis",
      headerClasses: "bg-primary text-white",
    },
    {
      name: "Name",
      required: true,
      label: "Name",
      align: "left",
      field: (row: ApplicationDetails) => row.nameAndAddress,
      sortable: true,
    },
    {
      name: "percentageGuarantee",
      required: true,
      label: "% Guarantee",
      align: "left",
      field: (row: ApplicationDetails) => row.percentageGuarantee,
      format: (val: string) =>
        val === null ? "Form R not yet submitted" : `${val} %`,
      sortable: false,
    },
    {
      name: "maximumFacilityRequired",
      required: true,
      label: "Maximum Facility Required",
      align: "left",
      field: (row: ApplicationDetails) => row.maximumFacilityRequired,
      format: (val: string) =>
        val === null
          ? "Form R not yet submitted"
          : `E ${numberWithCommas(val)}`,
      sortable: true,
    },
    {
      name: "FacilityRequired",
      required: true,
      label: "Guarantee Required",
      align: "left",
      field: (row: ApplicationDetails) =>
        row.maximumFacilityRequired && row.percentageGuarantee
          ? row.maximumFacilityRequired * (row.percentageGuarantee / 100)
          : null,
      format: (val: number) =>
        val === null
          ? "Form R not yet submitted"
          : `E ${numberWithCommas(val.toFixed(2))}`,
      sortable: true,
    },
  ];

  status(status: ApplicationStatus) {
    return ApplicationStatus[status];
  }

  mounted() {
    agent.Applications.formT(this.$route.query.id as string).then((formT) => {
      this.formT = formT;
      this.loading = false;
    });
  }
}
